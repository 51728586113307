/* prettier-ignore */
.nav-land-cont{
  display: flex;

background-image: linear-gradient(to right, #051c2c, #0a2e43, #0f425b, #125774, #126d8d, #086e94, #006f9a, #0070a1, #005b93, #004684, #133172, #1e1b5d);  // background-image: linear-gradient(to left, #051932, #0a2c49, #0e4061, #0f5579, #0e6c91, #0f6c90, #106d8e, #126d8d, #125774, #0f425b, #0a2e43, #051c2c);

}
// prettier-ignore
.landing-container {
  display: flex;
  @include responsive-mix(("margin-top": (50px,100px,),),320px,1200px,"vw");

  /* prettier-ignore */
  .landing-left {
    @include responsive-mix(("padding": (15px,90px,),),320px,1200px,"vw");
    width: 40%;

    padding-bottom: 20px;
    .landing-left-content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      z-index: 10;
    }

    /* prettier-ignore */
    .landing-left-title {
      z-index: 10;

      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      color: $omni-white;
      @include responsive-mix(("font-size": (35px,50px,),),320px,1200px,"vw");
    }

    /* prettier-ignore */
    .landing-left-description {
      z-index: 10;
      font-family: "Montserrat", sans-serif;
      color: $omni-white;
      @include responsive-mix(("font-size": (16px,24px,),),320px,1200px,"vw");
      @include responsive-mix(("line-height": (20px,42px,),),320px,1200px,"vw");

    }
  }

  .landing-right {
    display: flex;
    width: 60%;
    height: 600px;

    .landing-image {
      z-index: 1;
      background-image: url("../../assets/nodes.jpg");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      transform: translate(-120px, 40px);

      // @responsive-mix()
      box-shadow: 0 32px 24px -24px rgba(0, 72, 109, 0.5);
    }
  }
}
// CANVAS
canvas {
  display: block; /* Removes the margin in the bottom of the canvas */
  background-color: transparent; /* Optional: if you want a black background */
  width: 100%;
  height: 100%;
}

// background-image: linear-gradient(to left, #1e1b5d, #133172, #004684, #005b93, #0070a1, #0a6e99, #146b92, #1c698a, #1e506e, #1a3851, #132336, #060d1c);
// background-image: linear-gradient(to left, #202678, #102467, #062056, #041c44, #061733, #07142c, #071025, #050b1e, #050920, #060621, #080423, #0b0124);

// background-image: linear-gradient(to left, #1e1b5d, #0d2b68, #003970, #004676, #00527a, #044f73, #0a4d6b, #104a64, #133a52, #122b3f, #0f1c2d, #060d1c);
