$omni-dark: #181818;
$omni-gray: #e4ecf0;
$omni-white: #f2f6f7;
$landing-blue: #0b0124;
$omni-blue: #2042fc;
$omni-forest: #051C2C;

// $omni-blue: #399ac2;
//#2E4266;
//#2EE0ED
//#FE64DA
//#080D27
//#092E36
//#07061E

//202678
//060D1C
//0B0124

//background-image: linear-gradient(to right, #4132aa, #0062cf, #008be6, #00b0f1, #35d4f8);
