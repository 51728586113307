@media screen and (max-width: 600px) {
  .landing-container {
    .landing-left {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;

      .landing-left-content {
        width: 100%;
      }
    }
    .landing-right {
      display: none;
      .landing-image {
        transform: translate(0px, 40px);
      }
    }
  }
  .contact-container {
    .contact-info-container {
      .contact-top {
        flex-direction: column;

        .contact-left {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .landing-container {
    .landing-left {
      display: flex;
      width: 100%;
      // background-image: url("../../assets/nodes_nbg_wh.svg");

      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: darken;
      // filter: brightness(50%);
      padding: 0;

      .landing-left-content {
        filter: brightness(100%);
        padding: 20px;
        width: 100%;
        background-color: #00000094;

        .demo-button {
          background-color: $omni-white;
          color: $landing-blue;
        }
        * {
          color: $omni-white;
        }
      }
    }
    .landing-right {
      display: none;
      .landing-image {
        transform: translate(0px, 40px);
      }
    }
  }
  #solutions-services-container {
    gap: 40px;
    padding: 0;
    .solutions-swiper-container {
      height: fit-content;
      // background-color: red;
      .solutions-container-left {
        width: 100%;
      }

      .solutions-right-image {
        object-fit: cover;
        border: none;
        height: 80px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .right-0 {
        background-image: url("../../assets/pic0.jpg");
      }
      .right-1 {
        background-image: url("../../assets/pic1.jpg");
      }
      .right-2 {
        background-image: url("../../assets/pic2.jpg");
      }

      .solutions-container-full-width {
        // background-color: red;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .solutions-swiper-full-width-text {
          .solutions-mb-text-title {
            //prettier-ignore
            @include responsive-mix(("line-height": (30px,36px,),),320px,800px,"vw");
            //prettier-ignore
            @include responsive-mix(("font-size": (25px,32px,),),320px,800px,"vw");
            margin-bottom: 24px;
          }
          .landing-offerings-mb-body {
            //prettier-ignore
            // @include responsive-mix(("font-size": (14px,16px,),),320px, 800px,"vw");
            //prettier-ignore
            @include responsive-mix(("font-size": (16px,20px,),),320px,1200px,"vw");
            //prettier-ignore
            @include responsive-mix(("line-height": (20px,32px,),),320px,1200px,"vw");
            //prettier-ignore
            // @include responsive-mix(("line-height": (20px,25px,),),320px,800px,"vw");
          }
        }
        .solutions-full-width-image {
          object-fit: cover;
          border: none;
          height: 120px;
          width: 100%;
          border-radius: 8px;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .full-width-0 {
          background-image: url("../../assets/pic0.jpg");
        }
        .full-width-1 {
          background-image: url("../../assets/pic1.jpg");
        }
        .full-width-2 {
          background-image: url("../../assets/pic2.jpg");
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .demo-button {
    max-width: 156px;
  }

  .challenge-swiper-container {
    .challenge-div {
      h3 {
        // prettier-ignore
        @include responsive-mix(("font-size": (16px,18px,),),320px,1200px,"vw");
      }
      p {
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .landing-container {
    position: relative;
    .landing-left {
      .landing-left-content {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        // background-color: black;
        .nodes-animation {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 6;
        }
        .node-sibling {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 8;
          backdrop-filter: blur(15px);
        }
      }
    }
  }
  .navbar-container {
    background-color: $omni-white;
    .navbar-left {
      // prettier-ignore

      .nav-brand {
        color: $omni-dark;
        // prettier-ignore
        @include responsive-mix(("font-size": (16px,32px,),),320px,700px,"vw");
      }
      .navbar-title {
        // prettier-ignore
        @include responsive-mix(("width": (50px,70px,),),320px,700px,"vw");
        // prettier-ignore
        @include responsive-mix(("height": (36px, 50px,),),320px,700px,"vw");

        background-image: url("../../assets/nodes_nbg_dark.svg");
      }
    }

    .navbar-right {
      .navbar-consult-container {
        .dark-nav-btn {
          display: none;
        }
      }
    }
    .navbar-links-container {
      display: none;
    }
  }
  .about-container {
    .about-header {
    }
    .about-content {
      display: flex;
      padding-top: 1rem;
      padding-bottom: 1rem;
      //prettier-ignore
      @include responsive-mix(("margin-right": (-15px,-90px,),),320px,1200px,"vw");
      //prettier-ignore
      //prettier-ignore
      @include responsive-mix(("margin-left": (-15px,-90px,),),320px,1200px,"vw");
      //prettier-ignore
      @include responsive-mix(("padding-right": (15px,90px,),),320px,1200px,"vw");
      //prettier-ignore
      @include responsive-mix(("padding-left": (15px,90px,),),320px,1200px,"vw");

      background-image: url("../../assets/circle.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .about-body {
        background-color: rgba(0, 0, 0, 0.5);
        color: $omni-white;
        width: 100% !important;
      }
      .about-image {
        display: none;
      }
    }
  }

  .challenge-container {
    .challenge-card-container {
      justify-content: center;

      #challenge-div {
        height: 400px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .navbar-left {
  }
  .navbar-consult-container {
    .demo-button {
    }
  }
}
