@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin responsive-mix($properties-map, $min-dimension: 320px, $max-dimension: 1200px, $dimension-type: "vw") {
  @each $property, $values in $properties-map {
    $min-value: nth($values, 1);
    $max-value: nth($values, 2);

    // Set the default property value
    #{$property}: $min-value;

    // Determine dimension unit and media query based on dimension type
    $dimension-unit: if($dimension-type == "vw", "width", "height");
    $min-media-query: if($dimension-type == "vw", "min-width", "min-height");
    $dimension: if($dimension-type == "vw", "100vw", "100vh");

    @media screen and (#{$min-media-query}: $min-dimension) {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (#{$dimension} - #{$min-dimension}) / #{strip-unit($max-dimension - $min-dimension)});
    }

    @media screen and (#{$min-media-query}: $max-dimension) {
      #{$property}: $max-value;
    }
  }
}

//Usage Width
// @include responsive-mix(('font-size': (1rem, 2rem), 'line-height': (1.2, 1.5)));

//Usage Height
//@include responsive-mix(('font-size': (1rem, 2rem), 'line-height': (1.2, 1.5)), 400px, 800px, 'vh');
