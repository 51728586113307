// prettier-ignore
.solutions-container {
  @include responsive-mix(("margin-top": (50px,100px,),),320px,1200px,"vw");


//prettier-ignore
  .tabs-container {
    display: flex;
    background-color: $omni-gray;
    flex-wrap: nowrap;
    list-style-type: none;
    text-decoration: none;
    border-radius: 12px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include responsive-mix(("padding-right": (40px,90px,),),320px,1200px,"vw");
  }

  .tabs {
  }

  .tab {
    color: $omni-gray;
    padding: 10px 15px;
    cursor: pointer;
  }

  .tab.active {
    background-color: $omni-blue;
  }

  .content {
    width: 70%; /* Adjust as needed */
    padding: 10px;
    background-color: $omni-gray;
  }

  .item {
    color: $omni-white;
    padding: 8px 0;
  }
}

// Solutions Swiper Styles
// prettier-ignore
#solutions-services-container {

  display: flex;
  flex-direction: column;
  height: fit-content;
  // background-color: $landing-blue;
  border-radius: 12px;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 80px;
  // @include responsive-mix(("padding-left": (15px,90px,),),320px,1200px,"vw");
  // @include responsive-mix(("padding-right": (15px,90px,),),320px,1200px,"vw");
}
// prettier-ignore
.solutions-swiper-container {
  display: flex;
  align-items: flex-start;
  margin: auto;
  height: fot-content;
  max-height: 600px;

  .even-layout-container, .odd-layout-container {
    display: flex;
    height: 100%;
    display: flex;
    column-gap: 5%;


    .solution-text-container{
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 25px;

      .solution-title{
        color: $omni-dark;
      font-weight: 500;

      @include responsive-mix(("line-height": (30px,60px,),),320px,1200px,"vw");
      @include responsive-mix(("font-size": (25px,46px,),),320px,1200px,"vw");
    }

    .solution-body{
      color: $omni-dark;

      @include responsive-mix(("font-size": (16px,20px,),),320px,1200px,"vw");
      @include responsive-mix(("line-height": (20px,32px,),),320px,1200px,"vw");

    }
  }
    .solution-image-container{
      width: 45%;

      .solution-image-0, .solution-image-1, .solution-image-2{
        object-fit: cover;
        border-radius: 20px;
        border: none;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .solution-image-0 {
        background-image: url("../../assets/pic0.jpg");
      }
      .solution-image-1 {
        background-image: url("../../assets/pic1.jpg");
      }
      .solution-image-2 {
        background-image: url("../../assets/pic2.jpg");
      }
    }
  }

  .even-layout-container{

  }
  .odd-layout-container {
  }
}
