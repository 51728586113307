@import "./module.scss";

* {
  color: $landing-blue;
  // color: red;
}
body {
  // background: rgb(11, 1, 36);
//   background: linear-gradient(90deg, rgba(11, 1, 36, 1) 0%, rgba(32, 38, 120, 1) 83%);
}
ul,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}
