.challenge-container,
.solutions-container,
.about-container {
  // padding-bottom: 500px;
  // background-color: $omni-forest;
  //prettier-ignore
  @include responsive-mix(("padding-left": (15px,90px,),),320px,1200px,"vw");
  //prettier-ignore
  @include responsive-mix(("margin-top": (50px,100px,),),320px,1200px,"vw");
  //prettier-ignore
  @include responsive-mix(("padding-right": (15px,90px,),),320px,1200px,"vw");

  .challenge-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    text-decoration: none;
    border-radius: 12px;
    position: relative;
    gap: 25px;
    //  background-color: #4A64ED;
    // background-image: linear-gradient(to right, #4132aa, #0062cf, #008be6, #00b0f1, #35d4f8);
    z-index: 3;
    padding: 1rem 0;

    //   animation-timing-function: linear;
    color: whitesmoke;
    // box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6), inset 200px 0px 0 20px #2042fc, inset 200px 200px 200px 20px #35d4f8, inset 300px 300px 0 0px #1880c9;

    #challenge-div {
      position: relative;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      height: 350px;
      text-align: center;
      justify-content: space-between;
      width: 400px;
      min-height: 250px;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
      border-radius: 1rem;

      .card-stripe {
        display: flex;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        height: 40px;
        width: 100%;
        // background-color: red;
        background-image: linear-gradient(170deg, #4132aa, #35d4f8, #4132aa, #35d4f8);
        background-size: 4000% 1000%;
        animation: gradient-animation 12s infinite;
        animation-timing-function: linear;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        height: 100%;

        .challenge-title-cont {
          display: flex;
          justify-content: center;
          height: 70px;
          margin-bottom: 20px;

          .challenge-card-title {
            text-align: left;
            display: flex;
            // color: $omni-white;
            width: 90%;
            font-size: 18px;
            font-weight: 500;
            height: 70px;
          }

          .png-container-ch {
            display: flex;
            width: 40px;
            height: 40px;
            margin-right: 15px;

            .burn-png {
              background-image: url("../../assets/exhausted.png");
            }

            .limits-png {
              background-image: url("../../assets/stop.png");
            }

            .hourglass-png {
              background-image: url("../../assets/hourglass.png");
            }

            .hourglass-png,
            .burn-png,
            .limits-png {
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              height: 100%;
              width: 100%;
            }
            height: 35px !important;
            width: 35px !important;
          }
        }
        p {
          // color: $omni-white;
          line-height: 1.5;
          text-align: left;
          height: fit-content;
        }
      }
    }
  }
  .swiper-wrapper {
    padding: 25px 0;

    .swiper-slide {
      margin: 0;
    }
  }
}

@keyframes gradient-animation {
  0% {
    /* Continue adjusting this
           position based on your bg size so the start and end
           of your animation will line up and be a seamless loop */
    background-position: 70% 70%;
  }
  100% {
    background-position: 0 0;
  }
}
