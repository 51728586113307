.about-container {
  .about-header {
    // color: $omni-white;
  }

  .about-content {
    display: flex;
    justify-content: space-between;

    .about-body {
      width: 50%;
      // border-radius: 12px;
      //prettier-ignore
      @include responsive-mix(("font-size": (16px,20px,),),320px,1200px,"vw");
      //prettier-ignore
      @include responsive-mix(("line-height": (20px,32px,),),320px,1200px,"vw");
      // color: $omni-white;
    }

    .about-image {
      //   height: 500px;
      width: 45%;
      border-radius: 20px;

      background-image: url("../../assets/circle.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
