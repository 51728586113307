//prettier-ignore
.contact-container {
    @include responsive-mix(("margin-top": (50px,100px,),),320px,1200px,"vw");
    .contact-info-container {
        background-color: $omni-forest;

        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 30px;

        @include responsive-mix(("padding-left": (15px,90px,),),320px,1200px,"vw");
        @include responsive-mix(("padding-right": (15px,90px,),),320px,1200px,"vw");

        .contact-header {

            color: $omni-white;
        }

        .contact-top {
            display: flex;
            padding: 20x;
            justify-content: space-between;
            .contact-left {
                flex-direction: column;
                height: fit-content;
                width: 40%;
                display: flex;
                font-weight: 300;
                flex-wrap: wrap;
                font-size: 18px;

                a {
                    color: $omni-white;
                    text-decoration: none;
                    width: fit-content;
                }

                .contact-contents-header {
                    color: $omni-white;

                    width: 100%;
                    font-weight: 400;
                    margin-bottom: 0.5rem;
                }
                .contact-links {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                }
            }
            .contact-right {
                font-size: 18px;
                font-weight: 300;
                color: $omni-white;

                .contact-email {
                    margin-bottom: 0.5rem;
                    width: 100%;
                    font-weight: 400;
                }

                p {
                    color: $omni-white;

                }
            }
        }

        .contact-divider {
            border-top: 1px solid $omni-white;
            width: 100%;
            margin: 20px 0;
        }

        .contact-bottom {
            .contact-socials {
                display: flex;
                align-items: center;
                justify-content: center;
                .linkedin-svg {
                    height: 24px;
                    width: 24px;
                    background-image: url("../../assets/linked-in.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            .contact-rights {
                color: $omni-white;
                margin: 10px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .contact-all-rights {
                color: $omni-white;

                    text-align: center;

                    .privacy-link-contact{
                        text-decoration: none;
                    }
                }

                a{
                color: $omni-white;

                }
            }
        }
    }
}

.privacy-container {
  padding: 100px 3rem;
  background-color: #acacac;

  * {
    color: $omni-dark;
  }
}
