@import "../Helpers/helpers";
@import "../Helpers/colors";

/* prettier-ignore */
.navbar-container {
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  @include responsive-mix(("height": (50px,100px,),),320px,1200px,"vw");
  @include responsive-mix(("padding-left": (15px,90px,),),320px,1200px,"vw");
  @include responsive-mix(("padding-right": (15px,90px,),),320px,1200px,"vw");


  /* prettier-ignore */
  .navbar-links-container {
    display: flex;
    height: 70px;
    align-items: center;
    @include responsive-mix(("column-gap": (16px,20px,),),320px,1200px,"vw");
    @include responsive-mix(("font-size": (14px,16px,),),320px,1200px,"vw");
    @include responsive-mix(("line-height": (18px,35px,),),320px,1200px,"vw");


    .nav_links_scroll{
      color: $omni_dark;
    }

    a {
      cursor: pointer;
      display: flex;
      color: $omni-white;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
    }
  }
/* prettier-ignore */
  .navbar-title {
    background-image: url("../../assets/nodes_nbg_wh.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @include responsive-mix(("width": (50px,70px,),),720px,1200px,"vw");
    @include responsive-mix(("height": (30px,70px,),),720px,1200px,"vw");
  }
  .title_scrolled{
    background-image: url("../../assets/nodes_nbg_dark.svg");

  }
  .navbar-left {
    display: flex;
    // @include responsive-mix(("column-gap": (5px,65px,),));

    .nav-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $omni-white;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-weight: 200;
    }
    .scrolled_nav {
      color: $omni-dark;
    }
  }

  .navbar-right {
    .navbar-consult-container {
    }
  }
  a {
    font-family: "DM Sans", sans-serif;
    text-decoration: none;
  }

}
.has_scrolled {
  background-color: $omni-white;
  transition: ease-in-out 0.2s;
  box-shadow: 0 16px 50px -20px rgba(50, 50, 93, 0.25), 0 10px 16px -18px rgba(0, 0, 0, 0.3);
}
/* prettier-ignore */
.demo-button {
  z-index: 9;
  cursor: pointer;
  border-radius: 6px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  max-width: 170px;
  padding: 0.5rem 1rem;
  background-color: $omni-blue;
  border: 1px $omni-blue solid;
  color: #fefefe;
  transition: all 0.2s;
  font-weight: 400;
  @include responsive-mix(("font-size": (14px,16px,),"line-height": (14px,16px,),),320px,1200px,"vw");
  @include responsive-mix(("line-height": (18px,25px,),),320px,1200px,"vw");

  &:hover{
    background-color: $omni-blue;
    border: 1px solid $omni-blue;
  }
}
.dark-nav-btn {
  background-color: transparent;
  color: $omni-white;
  border-color: $omni-white;
}

.dark_nav_btn {
  background-color: $omni-blue;
  border: 1px solid $omni-blue;
  color: $omni-white;
}

@media screen and (max-width: 720px) {
  .navbar-container {
    .navbar-title {
      // display: none;
      // background-image: url("../../assets/bg-omni-nodes.svg");
    }
  }
}
