/* prettier-ignore */
.navbar-container {
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  /* prettier-ignore */
  /* prettier-ignore */
}
@media screen and (min-width: 320px) {
  .navbar-container {
    height: calc(50px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container {
    height: 100px;
  }
}
@media screen and (min-width: 320px) {
  .navbar-container {
    padding-left: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container {
    padding-left: 90px;
  }
}
@media screen and (min-width: 320px) {
  .navbar-container {
    padding-right: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container {
    padding-right: 90px;
  }
}
.navbar-container .navbar-links-container {
  display: flex;
  height: 70px;
  align-items: center;
  column-gap: 16px;
  font-size: 14px;
  line-height: 18px;
}
@media screen and (min-width: 320px) {
  .navbar-container .navbar-links-container {
    column-gap: calc(16px + 4 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container .navbar-links-container {
    column-gap: 20px;
  }
}
@media screen and (min-width: 320px) {
  .navbar-container .navbar-links-container {
    font-size: calc(14px + 2 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container .navbar-links-container {
    font-size: 16px;
  }
}
@media screen and (min-width: 320px) {
  .navbar-container .navbar-links-container {
    line-height: calc(18px + 17 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container .navbar-links-container {
    line-height: 35px;
  }
}
.navbar-container .navbar-links-container .nav_links_scroll {
  color: #181818;
}
.navbar-container .navbar-links-container a {
  cursor: pointer;
  display: flex;
  color: #f2f6f7;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}
.navbar-container .navbar-title {
  background-image: url("../../assets/nodes_nbg_wh.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 50px;
  height: 30px;
}
@media screen and (min-width: 720px) {
  .navbar-container .navbar-title {
    width: calc(50px + 20 * (100vw - 720px) / 480);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container .navbar-title {
    width: 70px;
  }
}
@media screen and (min-width: 720px) {
  .navbar-container .navbar-title {
    height: calc(30px + 40 * (100vw - 720px) / 480);
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container .navbar-title {
    height: 70px;
  }
}
.navbar-container .title_scrolled {
  background-image: url("../../assets/nodes_nbg_dark.svg");
}
.navbar-container .navbar-left {
  display: flex;
}
.navbar-container .navbar-left .nav-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f2f6f7;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 200;
}
.navbar-container .navbar-left .scrolled_nav {
  color: #181818;
}
.navbar-container a {
  font-family: "DM Sans", sans-serif;
  text-decoration: none;
}

.has_scrolled {
  background-color: #f2f6f7;
  transition: ease-in-out 0.2s;
  box-shadow: 0 16px 50px -20px rgba(50, 50, 93, 0.25), 0 10px 16px -18px rgba(0, 0, 0, 0.3);
}

/* prettier-ignore */
.demo-button {
  z-index: 9;
  cursor: pointer;
  border-radius: 6px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  max-width: 170px;
  padding: 0.5rem 1rem;
  background-color: #2042fc;
  border: 1px #2042fc solid;
  color: #fefefe;
  transition: all 0.2s;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  line-height: 18px;
}
@media screen and (min-width: 320px) {
  .demo-button {
    font-size: calc(14px + 2 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .demo-button {
    font-size: 16px;
  }
}
@media screen and (min-width: 320px) {
  .demo-button {
    line-height: calc(14px + 2 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .demo-button {
    line-height: 16px;
  }
}
@media screen and (min-width: 320px) {
  .demo-button {
    line-height: calc(18px + 7 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .demo-button {
    line-height: 25px;
  }
}
.demo-button:hover {
  background-color: #2042fc;
  border: 1px solid #2042fc;
}

.dark-nav-btn {
  background-color: transparent;
  color: #f2f6f7;
  border-color: #f2f6f7;
}

.dark_nav_btn {
  background-color: #2042fc;
  border: 1px solid #2042fc;
  color: #f2f6f7;
}

/* prettier-ignore */
.nav-land-cont {
  display: flex;
  background-image: linear-gradient(to right, #051c2c, #0a2e43, #0f425b, #125774, #126d8d, #086e94, #006f9a, #0070a1, #005b93, #004684, #133172, #1e1b5d);
}

.landing-container {
  display: flex;
  margin-top: 50px;
  /* prettier-ignore */
}
@media screen and (min-width: 320px) {
  .landing-container {
    margin-top: calc(50px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .landing-container {
    margin-top: 100px;
  }
}
.landing-container .landing-left {
  padding: 15px;
  width: 40%;
  padding-bottom: 20px;
  /* prettier-ignore */
  /* prettier-ignore */
}
@media screen and (min-width: 320px) {
  .landing-container .landing-left {
    padding: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .landing-container .landing-left {
    padding: 90px;
  }
}
.landing-container .landing-left .landing-left-content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  z-index: 10;
}
.landing-container .landing-left .landing-left-title {
  z-index: 10;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #f2f6f7;
  font-size: 35px;
}
@media screen and (min-width: 320px) {
  .landing-container .landing-left .landing-left-title {
    font-size: calc(35px + 15 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .landing-container .landing-left .landing-left-title {
    font-size: 50px;
  }
}
.landing-container .landing-left .landing-left-description {
  z-index: 10;
  font-family: "Montserrat", sans-serif;
  color: #f2f6f7;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 320px) {
  .landing-container .landing-left .landing-left-description {
    font-size: calc(16px + 8 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .landing-container .landing-left .landing-left-description {
    font-size: 24px;
  }
}
@media screen and (min-width: 320px) {
  .landing-container .landing-left .landing-left-description {
    line-height: calc(20px + 22 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .landing-container .landing-left .landing-left-description {
    line-height: 42px;
  }
}
.landing-container .landing-right {
  display: flex;
  width: 60%;
  height: 600px;
}
.landing-container .landing-right .landing-image {
  z-index: 1;
  background-image: url("../../assets/nodes.jpg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  transform: translate(-120px, 40px);
  box-shadow: 0 32px 24px -24px rgba(0, 72, 109, 0.5);
}

canvas {
  display: block; /* Removes the margin in the bottom of the canvas */
  background-color: transparent; /* Optional: if you want a black background */
  width: 100%;
  height: 100%;
}

.challenge-container,
.solutions-container,
.about-container {
  padding-left: 15px;
  margin-top: 50px;
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    padding-left: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    padding-left: 90px;
  }
}
@media screen and (min-width: 320px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    margin-top: calc(50px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    margin-top: 100px;
  }
}
@media screen and (min-width: 320px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    padding-right: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .challenge-container,
  .solutions-container,
  .about-container {
    padding-right: 90px;
  }
}
.challenge-container .challenge-card-container,
.solutions-container .challenge-card-container,
.about-container .challenge-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  text-decoration: none;
  border-radius: 12px;
  position: relative;
  gap: 25px;
  z-index: 3;
  padding: 1rem 0;
  color: whitesmoke;
}
.challenge-container .challenge-card-container #challenge-div,
.solutions-container .challenge-card-container #challenge-div,
.about-container .challenge-card-container #challenge-div {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 350px;
  text-align: center;
  justify-content: space-between;
  width: 400px;
  min-height: 250px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
  border-radius: 1rem;
}
.challenge-container .challenge-card-container #challenge-div .card-stripe,
.solutions-container .challenge-card-container #challenge-div .card-stripe,
.about-container .challenge-card-container #challenge-div .card-stripe {
  display: flex;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 40px;
  width: 100%;
  background-image: linear-gradient(170deg, #4132aa, #35d4f8, #4132aa, #35d4f8);
  background-size: 4000% 1000%;
  animation: gradient-animation 12s infinite;
  animation-timing-function: linear;
}
.challenge-container .challenge-card-container #challenge-div .card-content,
.solutions-container .challenge-card-container #challenge-div .card-content,
.about-container .challenge-card-container #challenge-div .card-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont {
  display: flex;
  justify-content: center;
  height: 70px;
  margin-bottom: 20px;
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .challenge-card-title,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .challenge-card-title,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .challenge-card-title {
  text-align: left;
  display: flex;
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  height: 70px;
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  height: 35px !important;
  width: 35px !important;
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png {
  background-image: url("../../assets/exhausted.png");
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png {
  background-image: url("../../assets/stop.png");
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png {
  background-image: url("../../assets/hourglass.png");
}
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png,
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png,
.challenge-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png,
.solutions-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .hourglass-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .burn-png,
.about-container .challenge-card-container #challenge-div .card-content .challenge-title-cont .png-container-ch .limits-png {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}
.challenge-container .challenge-card-container #challenge-div .card-content p,
.solutions-container .challenge-card-container #challenge-div .card-content p,
.about-container .challenge-card-container #challenge-div .card-content p {
  line-height: 1.5;
  text-align: left;
  height: fit-content;
}
.challenge-container .swiper-wrapper,
.solutions-container .swiper-wrapper,
.about-container .swiper-wrapper {
  padding: 25px 0;
}
.challenge-container .swiper-wrapper .swiper-slide,
.solutions-container .swiper-wrapper .swiper-slide,
.about-container .swiper-wrapper .swiper-slide {
  margin: 0;
}

@keyframes gradient-animation {
  0% {
    /* Continue adjusting this
           position based on your bg size so the start and end
           of your animation will line up and be a seamless loop */
    background-position: 70% 70%;
  }
  100% {
    background-position: 0 0;
  }
}
.solutions-container {
  margin-top: 50px;
}
@media screen and (min-width: 320px) {
  .solutions-container {
    margin-top: calc(50px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-container {
    margin-top: 100px;
  }
}
.solutions-container .tabs-container {
  display: flex;
  background-color: #e4ecf0;
  flex-wrap: nowrap;
  list-style-type: none;
  text-decoration: none;
  border-radius: 12px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 40px;
}
@media screen and (min-width: 320px) {
  .solutions-container .tabs-container {
    padding-right: calc(40px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-container .tabs-container {
    padding-right: 90px;
  }
}
.solutions-container .tab {
  color: #e4ecf0;
  padding: 10px 15px;
  cursor: pointer;
}
.solutions-container .tab.active {
  background-color: #2042fc;
}
.solutions-container .content {
  width: 70%; /* Adjust as needed */
  padding: 10px;
  background-color: #e4ecf0;
}
.solutions-container .item {
  color: #f2f6f7;
  padding: 8px 0;
}

#solutions-services-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 12px;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 80px;
}

.solutions-swiper-container {
  display: flex;
  align-items: flex-start;
  margin: auto;
  height: fot-content;
  max-height: 600px;
}
.solutions-swiper-container .even-layout-container, .solutions-swiper-container .odd-layout-container {
  display: flex;
  height: 100%;
  display: flex;
  column-gap: 5%;
}
.solutions-swiper-container .even-layout-container .solution-text-container, .solutions-swiper-container .odd-layout-container .solution-text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 25px;
}
.solutions-swiper-container .even-layout-container .solution-text-container .solution-title, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-title {
  color: #181818;
  font-weight: 500;
  line-height: 30px;
  font-size: 25px;
}
@media screen and (min-width: 320px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-title, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-title {
    line-height: calc(30px + 30 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-title, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-title {
    line-height: 60px;
  }
}
@media screen and (min-width: 320px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-title, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-title {
    font-size: calc(25px + 21 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-title, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-title {
    font-size: 46px;
  }
}
.solutions-swiper-container .even-layout-container .solution-text-container .solution-body, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-body {
  color: #181818;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 320px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-body, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-body {
    font-size: calc(16px + 4 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-body, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-body {
    font-size: 20px;
  }
}
@media screen and (min-width: 320px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-body, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-body {
    line-height: calc(20px + 12 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .solutions-swiper-container .even-layout-container .solution-text-container .solution-body, .solutions-swiper-container .odd-layout-container .solution-text-container .solution-body {
    line-height: 32px;
  }
}
.solutions-swiper-container .even-layout-container .solution-image-container, .solutions-swiper-container .odd-layout-container .solution-image-container {
  width: 45%;
}
.solutions-swiper-container .even-layout-container .solution-image-container .solution-image-0, .solutions-swiper-container .even-layout-container .solution-image-container .solution-image-1, .solutions-swiper-container .even-layout-container .solution-image-container .solution-image-2, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-0, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-1, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-2 {
  object-fit: cover;
  border-radius: 20px;
  border: none;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.solutions-swiper-container .even-layout-container .solution-image-container .solution-image-0, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-0 {
  background-image: url("../../assets/pic0.jpg");
}
.solutions-swiper-container .even-layout-container .solution-image-container .solution-image-1, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-1 {
  background-image: url("../../assets/pic1.jpg");
}
.solutions-swiper-container .even-layout-container .solution-image-container .solution-image-2, .solutions-swiper-container .odd-layout-container .solution-image-container .solution-image-2 {
  background-image: url("../../assets/pic2.jpg");
}
.contact-container {
  margin-top: 50px;
}
@media screen and (min-width: 320px) {
  .contact-container {
    margin-top: calc(50px + 50 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .contact-container {
    margin-top: 100px;
  }
}
.contact-container .contact-info-container {
  background-color: #051C2C;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .contact-container .contact-info-container {
    padding-left: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .contact-container .contact-info-container {
    padding-left: 90px;
  }
}
@media screen and (min-width: 320px) {
  .contact-container .contact-info-container {
    padding-right: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .contact-container .contact-info-container {
    padding-right: 90px;
  }
}
.contact-container .contact-info-container .contact-header {
  color: #f2f6f7;
}
.contact-container .contact-info-container .contact-top {
  display: flex;
  padding: 20x;
  justify-content: space-between;
}
.contact-container .contact-info-container .contact-top .contact-left {
  flex-direction: column;
  height: fit-content;
  width: 40%;
  display: flex;
  font-weight: 300;
  flex-wrap: wrap;
  font-size: 18px;
}
.contact-container .contact-info-container .contact-top .contact-left a {
  color: #f2f6f7;
  text-decoration: none;
  width: fit-content;
}
.contact-container .contact-info-container .contact-top .contact-left .contact-contents-header {
  color: #f2f6f7;
  width: 100%;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.contact-container .contact-info-container .contact-top .contact-left .contact-links {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.contact-container .contact-info-container .contact-top .contact-right {
  font-size: 18px;
  font-weight: 300;
  color: #f2f6f7;
}
.contact-container .contact-info-container .contact-top .contact-right .contact-email {
  margin-bottom: 0.5rem;
  width: 100%;
  font-weight: 400;
}
.contact-container .contact-info-container .contact-top .contact-right p {
  color: #f2f6f7;
}
.contact-container .contact-info-container .contact-divider {
  border-top: 1px solid #f2f6f7;
  width: 100%;
  margin: 20px 0;
}
.contact-container .contact-info-container .contact-bottom .contact-socials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-container .contact-info-container .contact-bottom .contact-socials .linkedin-svg {
  height: 24px;
  width: 24px;
  background-image: url("../../assets/linked-in.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.contact-container .contact-info-container .contact-bottom .contact-rights {
  color: #f2f6f7;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-container .contact-info-container .contact-bottom .contact-rights .contact-all-rights {
  color: #f2f6f7;
  text-align: center;
}
.contact-container .contact-info-container .contact-bottom .contact-rights .contact-all-rights .privacy-link-contact {
  text-decoration: none;
}
.contact-container .contact-info-container .contact-bottom .contact-rights a {
  color: #f2f6f7;
}

.privacy-container {
  padding: 100px 3rem;
  background-color: #acacac;
}
.privacy-container * {
  color: #181818;
}

@media screen and (max-width: 600px) {
  .landing-container .landing-left {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .landing-container .landing-left .landing-left-content {
    width: 100%;
  }
  .landing-container .landing-right {
    display: none;
  }
  .landing-container .landing-right .landing-image {
    transform: translate(0px, 40px);
  }
  .contact-container .contact-info-container .contact-top {
    flex-direction: column;
  }
  .contact-container .contact-info-container .contact-top .contact-left {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 800px) {
  .landing-container .landing-left {
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    padding: 0;
  }
  .landing-container .landing-left .landing-left-content {
    filter: brightness(100%);
    padding: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5803921569);
  }
  .landing-container .landing-left .landing-left-content .demo-button {
    background-color: #f2f6f7;
    color: #0b0124;
  }
  .landing-container .landing-left .landing-left-content * {
    color: #f2f6f7;
  }
  .landing-container .landing-right {
    display: none;
  }
  .landing-container .landing-right .landing-image {
    transform: translate(0px, 40px);
  }
  #solutions-services-container {
    gap: 40px;
    padding: 0;
  }
  #solutions-services-container .solutions-swiper-container {
    height: fit-content;
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-left {
    width: 100%;
  }
  #solutions-services-container .solutions-swiper-container .solutions-right-image {
    object-fit: cover;
    border: none;
    height: 80px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #solutions-services-container .solutions-swiper-container .right-0 {
    background-image: url("../../assets/pic0.jpg");
  }
  #solutions-services-container .solutions-swiper-container .right-1 {
    background-image: url("../../assets/pic1.jpg");
  }
  #solutions-services-container .solutions-swiper-container .right-2 {
    background-image: url("../../assets/pic2.jpg");
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .solutions-mb-text-title {
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .solutions-mb-text-title {
    line-height: calc(30px + 6 * (100vw - 320px) / 480);
  }
}
@media screen and (max-width: 800px) and (min-width: 800px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .solutions-mb-text-title {
    line-height: 36px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .solutions-mb-text-title {
    font-size: calc(25px + 7 * (100vw - 320px) / 480);
  }
}
@media screen and (max-width: 800px) and (min-width: 800px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .solutions-mb-text-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 800px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .landing-offerings-mb-body {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .landing-offerings-mb-body {
    font-size: calc(16px + 4 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .landing-offerings-mb-body {
    font-size: 20px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .landing-offerings-mb-body {
    line-height: calc(20px + 12 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-swiper-full-width-text .landing-offerings-mb-body {
    line-height: 32px;
  }
}
@media screen and (max-width: 800px) {
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .solutions-full-width-image {
    object-fit: cover;
    border: none;
    height: 120px;
    width: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .full-width-0 {
    background-image: url("../../assets/pic0.jpg");
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .full-width-1 {
    background-image: url("../../assets/pic1.jpg");
  }
  #solutions-services-container .solutions-swiper-container .solutions-container-full-width .full-width-2 {
    background-image: url("../../assets/pic2.jpg");
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .demo-button {
    max-width: 156px;
  }
  .challenge-swiper-container .challenge-div h3 {
    font-size: 16px;
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) and (min-width: 320px) {
  .challenge-swiper-container .challenge-div h3 {
    font-size: calc(16px + 2 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) and (min-width: 1200px) {
  .challenge-swiper-container .challenge-div h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 800px) {
  .landing-container {
    position: relative;
  }
  .landing-container .landing-left .landing-left-content {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }
  .landing-container .landing-left .landing-left-content .nodes-animation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
  }
  .landing-container .landing-left .landing-left-content .node-sibling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 8;
    backdrop-filter: blur(15px);
  }
  .navbar-container {
    background-color: #f2f6f7;
  }
  .navbar-container .navbar-left .nav-brand {
    color: #181818;
    font-size: 16px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .navbar-container .navbar-left .nav-brand {
    font-size: calc(16px + 16 * (100vw - 320px) / 380);
  }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
  .navbar-container .navbar-left .nav-brand {
    font-size: 32px;
  }
}
@media screen and (max-width: 800px) {
  .navbar-container .navbar-left .navbar-title {
    width: 50px;
    height: 36px;
    background-image: url("../../assets/nodes_nbg_dark.svg");
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .navbar-container .navbar-left .navbar-title {
    width: calc(50px + 20 * (100vw - 320px) / 380);
  }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
  .navbar-container .navbar-left .navbar-title {
    width: 70px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .navbar-container .navbar-left .navbar-title {
    height: calc(36px + 14 * (100vw - 320px) / 380);
  }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
  .navbar-container .navbar-left .navbar-title {
    height: 50px;
  }
}
@media screen and (max-width: 800px) {
  .navbar-container .navbar-right .navbar-consult-container .dark-nav-btn {
    display: none;
  }
  .navbar-container .navbar-links-container {
    display: none;
  }
  .about-container .about-content {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    background-image: url("../../assets/circle.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .about-container .about-content {
    margin-right: calc(-15px + -75 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  .about-container .about-content {
    margin-right: -90px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .about-container .about-content {
    margin-left: calc(-15px + -75 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  .about-container .about-content {
    margin-left: -90px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .about-container .about-content {
    padding-right: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  .about-container .about-content {
    padding-right: 90px;
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .about-container .about-content {
    padding-left: calc(15px + 75 * (100vw - 320px) / 880);
  }
}
@media screen and (max-width: 800px) and (min-width: 1200px) {
  .about-container .about-content {
    padding-left: 90px;
  }
}
@media screen and (max-width: 800px) {
  .about-container .about-content .about-body {
    background-color: rgba(0, 0, 0, 0.5);
    color: #f2f6f7;
    width: 100% !important;
  }
  .about-container .about-content .about-image {
    display: none;
  }
  .challenge-container .challenge-card-container {
    justify-content: center;
  }
  .challenge-container .challenge-card-container #challenge-div {
    height: 400px;
  }
}
.about-container .about-content {
  display: flex;
  justify-content: space-between;
}
.about-container .about-content .about-body {
  width: 50%;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 320px) {
  .about-container .about-content .about-body {
    font-size: calc(16px + 4 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .about-container .about-content .about-body {
    font-size: 20px;
  }
}
@media screen and (min-width: 320px) {
  .about-container .about-content .about-body {
    line-height: calc(20px + 12 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .about-container .about-content .about-body {
    line-height: 32px;
  }
}
.about-container .about-content .about-image {
  width: 45%;
  border-radius: 20px;
  background-image: url("../../assets/circle.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.solutions-header,
.challenge-header,
.contact-header,
.about-header {
  font-weight: 300;
  margin-bottom: 36px;
}

* {
  color: #0b0124;
}

ul,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

